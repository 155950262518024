import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from "@angular/core";
import { ReportService } from "../../../../services/report.service";
import { Billing } from "../../../../pojo/Pricing";
import { OrganizationI } from "../../../../pojo/OrganizationI";
import { S25LoadingApi } from "../../../s25-loading/loading.api";
import { TypeManagerDecorator } from "../../../../main/type.map.service";
import { TelemetryService } from "../../../../services/telemetry.service";
import { EventService } from "../../../../services/event.service";
import { Report as Reports } from "../../../../pojo/Report";

@TypeManagerDecorator("s25-ng-pricing-org-summary")
@Component({
    selector: "s25-ng-pricing-org-summary",
    template: ` @if (init) {
        <table class="tblOrgSummary ngTblOrgSummary">
            <tbody>
                <tr>
                    @if (modelBean.hasFreshbooks && !summaryView) {
                        <th>Organizations Invoiced</th>
                        <td class="hide-on-mobile"></td>
                        <th>Organizations Not Invoiced</th>
                        <td class="hide-on-mobile"></td>
                        <th>Organizations Without Charge</th>
                        <td class="hide-on-mobile"></td>
                    }
                    <th>Print Invoice</th>
                </tr>
                <tr class="summary-data-row">
                    @if (modelBean.hasFreshbooks && !summaryView) {
                        <td class="tblOrgSummaryContent">
                            @if (invoiceOrgItems.length === 0) {
                                <span>none</span>
                            }
                            <div class="org-list-wrapper">
                                @for (org of invoiceOrgItems; track org; let isLast = $last) {
                                    <span>
                                        <a (click)="scrollTo(org.organization_id)"
                                            >{{ org.organization_name }}{{ !isLast ? ", " : "" }}</a
                                        >
                                    </span>
                                }
                            </div>
                        </td>
                        <td class="hide-on-mobile"></td>
                        <td class="tblOrgSummaryContent">
                            @if (billedOrgItems.length === 0) {
                                <span>none</span>
                            }
                            <div class="org-list-wrapper">
                                @for (org of billedOrgItems; track org; let isLast = $last) {
                                    <span>
                                        <a (click)="scrollTo(org.organization_id)"
                                            >{{ org.organization_name }}{{ !isLast ? ", " : "" }}</a
                                        >
                                    </span>
                                }
                            </div>
                        </td>
                        <td class="hide-on-mobile"></td>
                        <td class="tblOrgSummaryContent">
                            @if (noChargeOrgItems.length === 0) {
                                <span>none</span>
                            }
                            <div class="org-list-wrapper">
                                @for (org of noChargeOrgItems; track org; let isLast = $last) {
                                    <span> {{ org.organization_name }}{{ !isLast ? ", " : "" }} </span>
                                }
                            </div>
                        </td>
                        <td class="hide-on-mobile"></td>
                    }
                    <td class="tblOrgSummaryContent print">
                        <s25-loading-inline [model]="{}"></s25-loading-inline>
                        @if (modelBean.paymentsMode) {
                            <select
                                class="cn-form__control c-margin-bottom--half"
                                [(ngModel)]="selectedInvoice"
                                (ngModelChange)="detectChanges()"
                                id="invoiceSelector"
                            >
                                @if (!selectedInvoice) {
                                    <option [value]="undefined">Select Invoice</option>
                                }
                                @for (invoice of invoicesForPrint; track invoice) {
                                    <option [ngValue]="invoice">
                                        {{ invoice.billDefn.billName }}
                                    </option>
                                }
                            </select>
                        }
                        <s25-ng-button
                            [type]="'primary'"
                            [disabled]="!defaultInvoiceReport || (modelBean.paymentsMode && !selectedInvoice)"
                            [disabledReason]="
                                !defaultInvoiceReport ? printInvoiceDisabledReason : 'No invoice selected'
                            "
                            (click)="printInvoice()"
                            class="c-margin-left--half"
                        >
                            @if (!modelBean.paymentsMode && modelBean.relatedEventIds?.length) {
                                Print Related Events Invoice
                            } @else {
                                Print Default Invoice
                            }
                        </s25-ng-button>
                    </td>
                </tr>
            </tbody>
        </table>
    }`,
    styles: `
        .tblOrgSummary.ngTblOrgSummary {
            a {
                color: #2573a7 !important;
            }

            .tblOrgSummaryContent:has(select) {
                padding: 6px;
            }

            s25-loading-inline {
                margin: unset;
            }

            .org-list-wrapper {
                display: flex;
                flex-wrap: wrap;
            }

            @media screen and (max-width: 1100px) {
                .hide-on-mobile {
                    display: none;
                }

                tbody {
                    display: grid;
                    grid-auto-flow: column;
                }

                tr {
                    display: grid;
                    gap: 10px;
                }

                .summary-data-row {
                    grid-template-rows: repeat(4, 1fr);
                }
            }

            .print label {
                font-weight: bold;
                display: block;
            }
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25PricingOrgSummaryComponent implements OnInit {
    @Input() modelBean: PricingOrgSummary;
    @Input() summaryView: boolean;

    @Output() signalScroll: EventEmitter<string> = new EventEmitter<string>();

    init: boolean = false;
    invoiceOrgItems: OrganizationI[];
    billedOrgItems: OrganizationI[];
    noChargeOrgItems: OrganizationI[];
    selectedInvoice: BillingWithEventId;
    invoicesForPrint: BillingWithEventId[];

    defaultInvoiceReport: Reports.SimpleObject;
    printInvoiceDisabledReason = "No default invoice configured on event type";

    constructor(
        private cd: ChangeDetectorRef,
        private elementRef: ElementRef,
    ) {}

    async ngOnInit() {
        this.invoiceOrgItems = this.modelBean.allOrgs.filter((org: OrganizationI) => {
            return this.modelBean.invoiceOrgs.indexOf(org.organization_id) > -1;
        });

        this.billedOrgItems = this.modelBean.allOrgs.filter((org: OrganizationI) => {
            return (
                this.modelBean.billedOrgs.indexOf(org.organization_id) > -1 &&
                this.modelBean.invoiceOrgs.indexOf(org.organization_id) === -1
            );
        });

        this.noChargeOrgItems = this.modelBean.allOrgs.filter((org: OrganizationI) => {
            return this.modelBean.billedOrgs.indexOf(org.organization_id) === -1;
        });

        const reports = await EventService.getEventReports(this.modelBean.eventId);
        if (!this.modelBean.paymentsMode) {
            if (this.modelBean.relatedEventIds?.length) {
                this.defaultInvoiceReport = reports.find(
                    (report) => report.rpt_id === Reports.Reports.InvoiceRelated.id,
                );
                this.printInvoiceDisabledReason = `"Event Invoice (Related)" is not associated with this event type`;
            } else {
                this.defaultInvoiceReport = reports.find((report) => report.rpt_use === Reports.Use.Invoice);
            }
        } else {
            // -998 indicates the invoice summary, will potentially include summary report in the future
            this.invoicesForPrint = this.modelBean.invoices.filter((invoice: Billing) => invoice.id !== -998);
            this.defaultInvoiceReport = reports.find((report) => report.rpt_use === Reports.Use.Payment);
        }

        this.init = true;

        this.cd.detectChanges();
    }

    scrollTo(orgId: number | string) {
        this.signalScroll.emit(`#pricing_org_list_${this.modelBean.eventId}_${orgId}`);
    }

    async printInvoice() {
        TelemetryService.sendWithSub("Pricing", "Event", "PrintInvoice");
        S25LoadingApi.init(this.elementRef.nativeElement);
        await ReportService.invoiceReport(
            this.modelBean.eventId,
            !this.modelBean.paymentsMode ? null : this.selectedInvoice.id,
            this.defaultInvoiceReport,
        );
        S25LoadingApi.destroy(this.elementRef.nativeElement);
        this.cd.detectChanges();
    }

    detectChanges() {
        this.cd.detectChanges();
    }
}

type BillingWithEventId = Billing & { eventId?: number };

export type PricingOrgSummary = {
    billedOrgs: number[];
    invoiceOrgs: number[];
    allOrgs: OrganizationI[];
    eventId: number;
    relatedEventIds: number[];
    hasFreshbooks: boolean;
    evBillId: number;
    locatorMap: { [key: number]: string };
    invoices: Billing[];
    paymentsMode: boolean;
};
