import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    Renderer2,
    signal,
} from "@angular/core";
import { LineItemI, PricingOccurrence } from "../../../../pojo/Pricing";
import { TypeManagerDecorator } from "../../../../main/type.map.service";
import { Table } from "../../../s25-table/Table";

@TypeManagerDecorator("s25-ng-pricing-item")
@Component({
    selector: "s25-ng-pricing-item",
    template: `
        <div class="pricing-item-wrapper" [class.expand-item]="isExpandable">
            <!-- Object Name - cannot hide row -->
            @if (!modelBean.reservations?.length && modelBean.total_charge && !isExpandable) {
                <span>{{ modelBean.itemName }}</span>
            }

            <!-- Object Name - can hide row -->
            @if (!modelBean.total_charge && !isExpandable) {
                <div
                    tabindex="0"
                    aria-label="Hide Line Item Row"
                    class="line-item-name"
                    (click)="handleRowVisibility()"
                    (keydown.enter)="handleRowVisibility()"
                >
                    <span class="pricing-item">{{ modelBean.itemName }}</span>
                    <span class="c-margin-left--quarter">
                        <s25-popover
                            [modelBean]="{ popoverTemplate: 'Items totalling $0 can be hidden', isTooltip: true }"
                        >
                            <s25-ng-icon [type]="'no'"></s25-ng-icon>
                        </s25-popover>
                    </span>
                </div>
            }
            <!-- Expandable Row, ie Occurrence or Payment Breakdown -->
            @if (this.isExpandable) {
                <button
                    class="c-textButton occ-expand-button-{{ modelBean.charge_to_id }}"
                    [class.expanded]="toggleShowData()"
                    (click)="handleShowToggle()"
                    aria-label="Expand or Collapse Reservations"
                >
                    <s25-ng-icon [type]="'caretRight'"></s25-ng-icon>
                    {{ modelBean.itemName }}
                </button>
            }
        </div>
    `,
    styles: `
        :host ::ng-deep .c-textButton {
            display: flex;
            padding: unset;
            text-align: left;
        }

        :host ::ng-deep svg {
            transition: transform 0.3s;
        }

        :host ::ng-deep .expanded svg {
            transform: rotate(90deg);
        }

        :host ::ng-deep .c-svgIcon {
            position: relative;
            bottom: 1px;
            filter: invert(33%) sepia(86%) saturate(469%) hue-rotate(160deg) brightness(101%) contrast(87%);
        }

        .line-item-name:hover {
            cursor: pointer;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25PricingItemComponent implements OnInit {
    @Input() modelBean: PricingItemModel;
    @Input() isExpandable: boolean;

    @Output() showData: EventEmitter<Table.NewRowModel> = new EventEmitter<Table.NewRowModel>();
    @Output() signalHiddenRows: EventEmitter<HTMLTableElement> = new EventEmitter<HTMLTableElement>();

    toggleShowData = signal(false);
    tableEl: HTMLTableElement;
    rowEl: HTMLTableRowElement;

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
    ) {}

    ngOnInit() {
        this.tableEl = this.elementRef.nativeElement.offsetParent.offsetParent;
        this.rowEl = this.elementRef.nativeElement.parentElement.parentElement;
    }

    handleShowToggle() {
        const rows = Array.from(this.tableEl.children[2].children);
        const rowIndex = rows.indexOf(this.rowEl);

        const tableData: Table.NewRowModel = {
            table: this.tableEl,
            row: this.rowEl,
            rowIndex: rowIndex + 1,
            action: this.toggleShowData() ? "delete" : "create",
            data: this.modelBean,
        };

        this.toggleShowData.update((prevShowData) => !prevShowData);
        this.showData.emit(tableData);
    }

    handleRowVisibility() {
        this.renderer.addClass(this.rowEl, "ngHidden");
        this.signalHiddenRows.emit(this.tableEl);
    }
}

type PricingItemModel = {
    itemName: string;
    reservations: PricingOccurrence[];
    isPayment?: boolean;
} & Pick<LineItemI, "charge_to_id" | "total_charge" | "total_tax">;
